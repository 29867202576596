@import '../../styles/customMediaQueries.css';
@import '../../styles/gridDefaults.scss';

.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex: 1;
  flex-direction: column;

  padding-top: 1px;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--failColor);
}

.formMargins {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.title {
  margin-bottom: 0px;

  @media (--viewportMedium) {
    margin-bottom: 0  px;
  }
}

.description {
  margin-bottom: 0px;

  @media (--viewportMedium) {
    margin-bottom: 0  px;
  }
  flex-shrink: 0;
}

.tip {
  margin-top: 10px;
  margin-bottom: 30px;
  color: rgb(181, 181, 181);
  font-size: 14px;
  white-space: pre-wrap;
}

.category {
  composes: formMargins;
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 100px;
  }
}

.dimensionContainer {
  font-size: 12px;

  @media (max-width: 1024px) {
    margin-bottom: 50px;
  }

  .input {
    margin-top: 15px;
    max-width: 110px;

    label {
      font-size: 12px;
    }
  }

  .textarea {
    width: 100%;
    height: 100px; /* You can adjust the height as needed */
    resize: vertical; /* Allow vertical resizing */
    padding: 8px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
  
}