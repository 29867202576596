.initialMessageTextArea {
    overflow: hidden;
    overflow-wrap: break-word;
    resize: none;
    height: 42px;
    border-bottom-color: var(--successColor);
}

.cardHolderName {
    border-bottom-color: var(--successColor);
    margin-bottom: 15px;
}