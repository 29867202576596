@import '../../styles/customMediaQueries.css';
@import '../../styles/gridDefaults.scss';

.root {
  display: flex;
  flex-direction: column;
}

.bookingDates {
  flex-shrink: 0;
  margin-bottom: 38px;

  /* Ensure that calendar dropdown gets some stacking context relative to other form items below */
  z-index: 1;
}

.priceBreakdownContainer {
  padding: 0 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 0;
    margin-top: 1px;
  }
}

.priceBreakdownTitle {
  /* Font */
  color: var(--matterColorAnti);

  margin-top: 5px;
  margin-bottom: 14px;
  @media (--viewportMedium) {
    margin-top: 5px;
    margin-bottom: 26px;
  }
}

.receipt {
  flex-shrink: 0;
  margin: 0 0 24px 0;
}

.error {
  color: var(--failColor);
  margin: 0 24px;
  display: inline-block;
}

.spinner {
  margin: auto;
}

.sideBarError {
  composes: h4 from global;
  color: var(--failColor);
  margin: 0 24px 12px 24px;

  @media (--viewportMedium) {
    margin: 0 0 12px 0;
  }
}

.smallPrint {
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColorAnti);
  text-align: center;
  margin: auto 24px 20px 24px;
  flex-shrink: 0;

  @media (--viewportMedium) {
    margin-top: auto;
    margin-bottom: 20px;
  }

  @media (--viewportLarge) {
    margin-top: 4px;
    margin-bottom: 21px;
  }
}

.submitButtonWrapper {
  flex-shrink: 0;
  padding: 0 24px 24px 24px;
  width: 100%;

  @media (--viewportMedium) {
    padding: 0;
    width: 100%;
  }
}

.textField {
  margin-bottom: 20px;
  padding: 0 20px 0 20px;

  @media (--viewportMedium) {
    margin-bottom: 20px;
    padding: 0;
  }

  @media (--viewportLarge) {
    margin-bottom: 20px;
    padding: 0;
  }
}

.additionalInfo {
  display: flex;
  flex-direction: column;
  font-size: 11px;
  color: lightgray;
}

.extraText {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;
  font-weight: 600;
  border: 2px solid var(--marketplaceColor);
  border-radius: 25px;
  padding: 10px;
  margin-bottom: 2pc;
  margin-top: 1pc;
}
.extraText2 {
  text-decoration: underline;
}

.phone {
  width: auto;
  height: 3pc;
}

.paymentOptionFields {
  padding: 0 24px;
  margin-top: 15px;
  margin-bottom: 15px;

  @media (--viewportMedium) {
    padding: 0;
  }
}
