@import '../../styles/customMediaQueries.css';
@import '../../styles/gridDefaults.scss';

.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex: 1;
  flex-direction: column;

  padding-top: 1px;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.formMargins {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.title {
  composes: formMargins;
}

.policy {
  margin-bottom: 5px;

  @media (--viewportMedium) {
    margin-bottom: 5px;
  }
  flex-shrink: 0;
}

.error {
  color: var(--failColor);
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 100px;
  }
}

.installationWaived {
  margin-top: 40px;
}

.tip {
  margin-top: 10px;
  margin-bottom: 30px;
  color: rgb(181, 181, 181);
  font-size: 14px;
}

.dimensionContainer {
  font-size: 12px;

  @media (max-width: 1024px) {
    margin-bottom: 50px;
  }

  .input {
    margin-top: 15px;
    max-width: 110px;

    label {
      font-size: 12px;
    }
  }
}

.supportedFiles {
  margin-top: 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
    margin-bottom: 32px;
  }
}