@import '../../styles/gridDefaults.scss';

.intervalLabelWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;

    font-weight: var(--fontWeightMedium);
    font-size: 14px;
    line-height: 24px;
}

.totalDivider {
    /* dimensions */
    width: 100%;
    height: 1px;
    margin: 12px 0 5px 0;

    border: none;
    background-color: var(--matterColorNegative);

    @media (--viewportMedium) {
        margin: 7px 0 0px 0;
    }
}

.totalWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    padding: 6px 0;
    margin-top: 15px;

    font-weight: var(--fontWeightMedium);
    font-size: 15px;
    line-height: 24px;
}

.totalAmount {
    font-weight: var(--fontWeightSemiBold);
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.2px;
}

.subscriptionsInfo {
    font-weight: var(--fontWeightRegular);
    font-size: 13px;
}

.viewPaymentScheduleLink {
    padding-top: 10px;
    text-align: center;
    margin: 0 auto;
    font-size: 15px;
    font-weight: 600;
}

.subscriptionDateRange {
    font-size: 12px;
}

.paymentPeriodContainer {
    padding: 5px;
    border: 1px solid var(--matterColorNegative);
    margin-bottom: 10px;

    .paymentBreakdown {}

    .payoutBreakdown {
        background-color: #dbdbdb;
        padding: 10px 5px;
        margin-bottom: 5px;
    }
}

.paymentStatus {
    color: white;
    padding: 0 5px;
    border-radius: 2px;
    margin-top: 5px;
    margin-bottom: 5px;

    &.paid {
        background-color: var(--successColor);
    }

    &.scheduled {
        background-color: var(--marketplaceColorLight);
    }

    &.failed,
    &.cancelled {
        background-color: var(--failColor);
    }
}

.refundNotes {
    background-color: #dbdbdb;
    padding: 5px;
    margin-top: 10px;
    border-radius: 2px;

    p {
        font-size: 14px;
        margin: unset;
        padding: unset;
    }
}