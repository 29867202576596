@import '../../styles/customMediaQueries.css';
@import '../../styles/gridDefaults.scss';

.totalDivider {
  width: 100%;
  height: 1px;
  border: none;
  background-color: var(--matterColorNegative);
}

.totalWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  padding-top: 5px;
  padding-bottom: 5px;
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 24px;
}

.refundBreakdownLineItems {
  width: 100%;
  margin-bottom: 20px;
}

.dividerLine {
  border: 1px solid var(--matterColorNegative);
  margin-top: 15px;
  margin-bottom: 15px;
}

.lineItem {
  @apply --marketplaceH4FontStyles;
  margin: 0;

  @media (--viewportMedium) {
    padding-top: 6px;
    padding-bottom: 2px;
  }

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.itemLabel {
  flex: 1;
}

.subTotalLineItem {
  @apply --marketplaceH4FontStyles;
  font-weight: var(--fontWeightBold);
  margin: 0;
  padding: 5px 0 1px 0;

  @media (--viewportMedium) {
    padding: 6px 0 2px 0;
  }

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.lineItemTotal {
  @apply --marketplaceH4FontStyles;
  margin: 0;
  padding-top: 6px;
  padding-bottom: 6px;

  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;

  @media (--viewportMedium) {
    padding-bottom: 2px;
  }
}

.cancelButton {
  background-color: var(--failColor);
  min-height: 50px;
  width: 100px;

  &:hover {
    background-color: transparent;
    color: var(--failColor);
    border: 1px solid var(--failColor);
  }
}

.exitButton {
  background-color: transparent;
  border: 1px solid var(--marketplaceColor);
  color: var(--marketplaceColor);
  min-height: 50px;
  width: 100px;
  float: right;

  &:hover {
    background-color: var(--marketplaceColor);
    color: white;
  }
}

.confirmCancellationInput {
  width: unset;
}

.confirmCancellationText {
  margin-top: -10px;
}

.cancelErrorMessage {
  color: var(--failColor);
}
