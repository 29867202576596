@import '../../../styles/gridDefaults.scss';

.basePriceLabelWrapper {
    & h2 {
        margin: 10px 0 0 0;
        font-weight: var(--fontWeightSemiBold);
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.2px;
    }

    & p {
        margin: 0;
        font-weight: var(--fontWeightMedium);
        font-size: 14px;
        line-height: 24px;
    }
}

.dueLaterWrapper {
    & h2 {
        margin: 10px 0 0 0;
        font-weight: var(--fontWeightSemiBold);
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.2px;
    }
}

.intervalLabelWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;

    font-weight: var(--fontWeightMedium);
    font-size: 14px;
    line-height: 24px;
}

.totalDivider {
    width: 100%;
    height: 1px;
    border: none;
    background-color: var(--matterColorNegative);
}

.totalWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    padding-top: 5px;
    padding-bottom: 5px;
    font-weight: var(--fontWeightMedium);
    font-size: 14px;
    line-height: 24px;
}

.subscriptionsInfo {
    font-weight: var(--fontWeightRegular);
    font-size: 13px;
}

.viewPaymentScheduleLink {
    padding-top: 10px;
    padding-bottom: 20px;
    text-align: center;
    margin: 0 auto;
    font-size: 15px;
    font-weight: 600;
}

.subscriptionDateRange {
    font-size: 12px;
}

.dueLaterProviderBreakdown {
    background-color: #dbdbdb;
    padding: 10px;
    margin-top: 10px;
    border-radius: 2px;
}