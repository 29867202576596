@import '../../styles/gridDefaults.scss';

.container {
  margin: -25px 48px 20px 48px;

  .innerContainer {
    background-color: #dbdbdb;
    padding: 10px;
    border-radius: 2px;  
  }

  .cancelButton {
    color: var(--failColor);
    background-color: transparent;
    border: 1px solid var(--failColor);
    float: right;
    border-radius: 4px;

    &:hover {
      background-color: var(--failColor);
      color: white;
    }
  }
}

.cardIcon {
  margin-right: 10px;
}

.actionRequiredText {
  color: var(--failColor);
}

.expiry {
  font-size: 14px;
}
