@import '../../styles/customMediaQueries.css';
@import '../../styles/gridDefaults.scss';

.content {
    @media (--viewportMedium) {
        margin: 32px auto 0 auto;
        max-width: 564px;
    }

    @media (--viewportLarge) {
        margin: 0;
    }
}

.title {
    margin-top: 8px;
    margin-bottom: 19px;

    @media (--viewportMedium) {
        margin-bottom: 36px;
    }
}

.taxVerificationStatusText {
    margin-bottom: 35px;
    text-transform: none;

    .verified {
        color: var(--successColor);
    }

    .pending {
        color: var(--attentionColor);
    }

    .declined {
        color: var(--failColor);
    }
}

.taxInfoSubmit {
    float: right;
    flex-shrink: 0;
    color: white;

    @media (--viewportLarge) {
        display: inline-block;
        width: 125px;
    }
}

.disabledInputPlaceholder {
    margin-top: 8px;
    background-color: var(--matterColorNegative);
    color: var(--matterColorDark);
    padding: 10px;
    border-radius: 8px;
    overflow: hidden;
}