@import '../../styles/customMediaQueries.css';

.root {
  composes: h4 from global;
  margin-top: 6px;
  margin-bottom: 12px;
  color: var(--failColor);
  overflow: hidden;

  @media (--viewportMedium) {
    margin-top: 6px;
    margin-bottom: 10px;
  }
}
