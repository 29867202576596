// @import '../../styles/gridDefaults.scss';

.customOfferForm {
  margin-top: 15px;
}

.col12 {
  margin-bottom: 15px;
}

.submitButton {
  margin-top: 15px;
}

.error {
  color: var(--failColor);
}

.priceBreakdownContainer {
  width: 100%;
}

.iconSpinner {
  margin: 0 auto;
  width: 100%;
}

.errorRequiredField {
  color: var(--failColor);
}

.paymentOptionFields {
  position: unset;
  z-index: 0;
}
