.toolTip {
  color: var(--marketplaceColor);
  display: inline-block;
  position: relative;
  top: 0;
  left: 5px;
}

.tooltipContentContainer {
  max-width: 250px;
  @media (--viewportMedium) {
    max-width: 300px;
  }
  @media (--viewportLarge) {
    max-width: 500px;
  }
}
