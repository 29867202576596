@import '../../styles/customMediaQueries.css';
@import '../../styles/gridDefaults.scss';

.root {
  /* Dimensions */
  width: 100%;
  height: auto;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--failColor);
}

.priceInputHidden {
  visibility: hidden;
  position: fixed;
}

.priceInput {
  flex-shrink: 0;
  width: 100%;
  margin-top: 20px;
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 100px;
  }
}

.subscriptionPriceField {
  margin-top: 20px;
}

.taxInfoSection {
  margin-top: 30px;
  padding: 10px;
  border: 1px solid #ccc;

  .title {
    font-size: 16px;
    margin-bottom: 15px;
  }

  .field {
    @media (--viewportMaxSmall) {
      margin-bottom: 10px;
    }
  }

  .editBtn {
    margin-top: 10px;
    background-color: var(--marketplaceColor);
    color: var(--matterColorLight);
    font-weight: 500;
    border: unset;
    border-radius: 2px;
    width: 80px;
    padding: 5px;
    float: right;

    @media (--viewportMaxSmall) {
      float: left;
    }

    &:hover {
      cursor: pointer;
      background-color: var(--marketplaceColorDark);
    }
  }
}

.colTaxInfo {
  flex: $flexColDefault;
  width: 33.33%;

  @media (--viewportMaxSmall) {
    flex: $flexColDefault;
    width: 100%;
  }
}

.yearTermDiscount {
  .headingTitle {
    font-weight: var(--fontWeightSemiBold);
    margin-top: 20px;
    margin-bottom: 8px;
  }

  .title {
    line-height: 2.5em;
  }

  .input {
    max-width: 100px;
    margin-left: 25px;
  }
}

.paymentOptionsSection {
  margin: 15px 0;
}

.listingFullPaymentOptionDiscount {
  max-width: 100px;
}
