@import '../../styles/customMediaQueries.css';

.inputRoot {
  /* Contain repainting to this component only */
  transform: translate3d(0, 0, 0);

  /* Override react-dates default styles to match input styles */

  & :global(.SingleDatePicker) {
    display: block;
  }
  & :global(.SingleDatePicker_picker__directionLeft) {
    /* !important is added to top because react-dates uses inline style for height */
    /* Similar problem as in issue: https://github.com/airbnb/react-dates/issues/947 */
    top: 36px !important;
    width: 100%;
    min-height: calc(100vh - 252px);
    background-color: var(--marketplaceColor);

    @media (--viewportMedium) {
      /* !important is added to top because react-dates uses inline style for height */
      /* Similar problem as in issue: https://github.com/airbnb/react-dates/issues/947 */
      top: 40px !important;
      min-height: auto;
      box-shadow: var(--boxShadowPopup);
      border-radius: 0 0 2px 2px;
    }
  }

  & :global(.DateInput_input) {
    font-family: var(--fontFamily);
    font-weight: var(--fontWeightMedium);
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.1px;

    /* Dimensions */
    display: block;
    width: 100%;
    margin: 0;
    padding: 5px 0 5px 0;

    /* Borders */
    border: none;
    border-radius: 0;
    border-bottom-style: solid;
    border-bottom-width: 2px;
    border-bottom-color: var(--attentionColor);

    transition: border-bottom-color var(--transitionStyle);
    transition: all 0.15s ease-out;
    background: none;

    &::placeholder {
      color: var(--matterColorAnti);
    }

    @media (--viewportMedium) {
      font-size: 16px;
      line-height: 32px;
      padding: 4px 0 9px 0;
      border-bottom-width: 3px;
    }
  }
  & :global(.SingleDatePickerInput) {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border: none;
    background: none;
  }
  & :global(.DayPicker__horizontal) {
    margin: 0 auto;
    background-color: var(--marketplaceColor);
    box-shadow: none;
  }

  & :global(.DayPickerNavigation__horizontal) {
    position: relative;
    width: 100%;
  }

  & :global(.DayPickerNavigation_button__horizontal) {
    padding: 6px 9px;
    top: 16px;
    position: absolute;
    cursor: pointer;
    display: inline;

    &:first-of-type {
      left: 24px;
    }

    &:last-of-type {
      right: 24px;
    }
  }

  & :global(.DayPickerNavigation_button) {
    color: var(--matterColorLight);
    border: 0;
  }

  & :global(.CalendarMonth),
  & :global(.CalendarMonthGrid) {
    background-color: transparent;
  }
  & :global(.DateInput) {
    display: block;
    width: 100%;
  }
  & :global(.DayPicker_weekHeader) {
    color: var(--matterColorLight);
    top: 57px;
  }

  & :global(.DayPicker_weekHeader_li) {
    font-weight: 400;
  }

  & :global(.CalendarMonth_caption) {
    font-weight: 400;
    font-size: 21px;
    line-height: 24px;
    color: var(--matterColorLight);

    margin: 1px 0 14px;

    @media (--viewportMedium) {
      line-height: 32px;
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  & :global(.CalendarDay__default) {
    background-color: var(--marketplaceColor);
    border: 0;
    padding: 0;
    width: 100%;
    height: 100%;
  }
  & :global(.CalendarDay) {
    font-weight: var(--fontWeightMedium);
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0;

    color: var(--matterColorLight);
    border: 0;
    margin-top: 0;
    margin-bottom: 0;

    @media (--viewportMedium) {
      font-weight: var(--fontWeightMedium);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  /* Add an underline for '.renderedDay' */
  & :global(.CalendarDay__today .renderedDay) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateInput_selectionHeight);
    background-image: url("data:image/svg+xml;utf8,<svg width='14' height='2' viewBox='0 0 14 2' xmlns='http://www.w3.org/2000/svg'><path d='M0 0h14v2H0z' fill='%23FFF' fill-rule='evenodd'/></svg>");
    background-position: center 28px;
  }

  /* Remove default bg-color and use our extra span instead '.renderedDay' */
  & :global(.CalendarDay__hovered_span),
  & :global(.CalendarDay__selected_span) {
    background-image: transparent;
    background-color: transparent;
  }
  & :global(.CalendarDay__hovered_span .renderedDay) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateInput_selectionHeight);
    background-color: var(--DateInput_hoveredOverlayColor);
  }
  & :global(.CalendarDay__selected_span .renderedDay) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateInput_selectionHeight);
    background-color: var(--successColor);
    transition: all 0.2s ease-out;
  }
  /* Remove default bg-color and use our extra span instead '.renderedDay' */
  & :global(.CalendarDay__selected) {
    background-color: transparent;
    background-image: none;
  }
  & :global(.CalendarDay__selected .renderedDay) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateInput_selectionHeight);
    background-color: var(--successColor);
    border-radius: calc(var(--DateInput_selectionHeight) / 2);
  }
  /* Remove default bg-color and use our extra span instead '.renderedDay' */
  & :global(.CalendarDay__after-hovered) {
    background-color: transparent;
  }
  & :global(.CalendarDay__after-hovered .renderedDay) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateInput_selectionHeight);
    background-color: var(--DateInput_hoveredOverlayColor);
  }
  & :global(.CalendarDay:hover .renderedDay) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateInput_selectionHeight);
    background-color: var(--DateInput_hoveredOverlayColor);
  }
  /* Remove default bg-color and use our extra span instead '.renderedDay' */
  & :global(.CalendarDay__blocked_out_of_range),
  & :global(.CalendarDay__blocked_out_of_range:active),
  & :global(.CalendarDay__blocked_out_of_range:hover) {
    background-color: transparent;
    color: var(--marketplaceColorDark);
    border: 0;
  }
  /* Remove default bg-color and use our extra span instead '.renderedDay' */
  & :global(.CalendarDay__blocked_calendar),
  & :global(.CalendarDay__blocked_calendar:active),
  & :global(.CalendarDay__blocked_calendar:hover) {
    background-color: transparent;
    color: var(--marketplaceColorDark);
    border: 0;
  }
  & :global(.CalendarDay__blocked_out_of_range .CalendarDay__blocked_calendar .renderedDay) {
    background-color: transparent;
  }
  & :global(.DateInput_fang) {
    display: none;
  }
  & :global(.CalendarMonth_caption) {
    text-transform: capitalize;
  }
}

/**
 * Mobile margins mean that labels, inputs (incl separate borders) get margins,
 * but calendar popup doesn't.
 */
.withMobileMargins {
  & :global(.SingleDatePickerInput) {
    width: calc(100vw - 48px);
    margin: 0 24px;

    @media (--viewportMedium) {
      width: 100%;
      margin: 0;
    }
  }

  & :global(.SingleDatePicker_picker__directionLeft) {
    /* !important is added to top because react-dates uses inline style for height */
    /* Similar problem as in issue: https://github.com/airbnb/react-dates/issues/947 */
    top: 34px !important;
    border-top: 2px solid var(--matterColorDark);
    min-height: calc(100vh - 252px + 2px);

    @media (--viewportMedium) {
      /* !important is added to top because react-dates uses inline style for height */
      /* Similar problem as in issue: https://github.com/airbnb/react-dates/issues/947 */
      top: 40px !important;
      border-top: 0;
      min-height: auto;
    }
  }

  /* Create gutter between inputs */
  & :global(.DateInput) {
    width: 100%;
    background: none;
  }
}

.rootNextMonthIcon,
.rootPreviousMonthIcon {
  stroke: var(--matterColorLight);
  fill: var(--matterColorLight);
}
